import { extendTheme } from "@chakra-ui/react"


const theme = extendTheme({
  fonts: {
    heading: "Century Gothic",
    body: "Century Gothic",
  },
  textStyles: { 
    primary: {
        fontFamily: "Century Gothic"
    },
    secondary: {
        fontFamily: "MOLIKA"
    },
},
})

export default theme;