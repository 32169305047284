import { useEffect, useState } from "react";

type scrollDirectionProps = {
  sideMenuOpen : boolean
}

export const useScrollDirection = (props:scrollDirectionProps) => {
    const [scrollDirection, setScrollDirection] = useState<string>("up");
  
    useEffect(() => {
      let lastScrollY = window.pageYOffset;
      const screenWidth = window.innerWidth;
      const headerCrossPoint = (screenWidth >= 480 ? 200 : 100);
  
      const updateScrollDirection = () => {
        const scrollY = window.pageYOffset;

        
        const direction = scrollY > lastScrollY ? "down" : "up";
        if (direction !== scrollDirection && (scrollY - lastScrollY > 1 || scrollY - lastScrollY < -1) && scrollY > headerCrossPoint) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;

        if(props.sideMenuOpen) { 
          setScrollDirection("up") 
        }

      };
      window.addEventListener("scroll", updateScrollDirection); // add event listener
      return () => {
        window.removeEventListener("scroll", updateScrollDirection); // clean up
      }
    }, [scrollDirection, props.sideMenuOpen]);
  
    return scrollDirection;
  };