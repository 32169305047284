import { Box, Flex,Grid,Image, Spacer, Text, VStack, Center, HStack} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import logo from '../images/logo.png';
import about from '../images/about.png';
import aboutTwo from "../images/about-us.jpg";
import portrait from '../images/portrait.png';
import { Header } from '../components/header';

import '../fonts/VoyageBold.ttf'; 


const About = () => {

    const getWindowDimensions = () => {
        const { innerWidth: width} = window;
        return width;
    }
    const [windowWidth, setWindowWidth] = useState(getWindowDimensions());
    const [mobile, setMobile] = useState(false);


    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
          if (window.innerWidth < 480) {
            setMobile(true);
          } else {
            setMobile(false);
          }
        };
        window.addEventListener('resize', handleResize);
        window.addEventListener('load', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('load', handleResize);
            }
      }, []);
    
    
    return (
        <div id="outer-container">
        <Box>
            <Header activePage={"ABOUT"} />
            <main id="page-wrap">
            {windowWidth < 1000 ? 

                <Box pb={100} pt={2} >
                    <Center py={[7,16]}>
                        <Text fontSize={[45,80]} textStyle={"secondary"} color="black" pr={3}>About Us</Text>
                        <Image src={about} height={["10vh","15vh"]} />
                    </Center> 
                    <Center px={10} >
                        <Text color="black" pr={3}>We are a small event hiring business based in South- East Melbourne. With our design backgrounds we have always wanted to start a little business. Which we are passionate about where we can express ourselves creatively and share our love for helping, celebrating and making someone's day unforgettable. We highly value capturing the little memories and bringing joy to your celebrations. We look forward to bringing these special moments to you 😊</Text>
                    </Center>
                    <Center py={2} px={10} >
                        <Image src={aboutTwo} borderRadius={'lg'} height={"50vh"} />
                    </Center>
                    <Center>
                        <br/>
                        <br/>
                        <Text fontSize={20} color="black" > <b>Savini</b></Text>
                    </Center>
                    <Center>
                        <Text color="black" px={10} >I am a current Master of Architecture student, specialising in software design, 3D model making and interior decor. I’ve worked with a number of organisations that value client and business relationships. My love for the world of creativity has flourished into this small business of ours. </Text>
                    </Center>
                    <Center>
                        <br/>
                        <br/>
                        <Text fontSize={20} color="black" > <b>Sandra</b></Text>
                    </Center>
                    <Center>
                        <Text color="black" px={10}>I am a visual arts teacher that has a strong passion for creativity and helping others. I’ve worked and volunteered in a number of different events and art programs, in which I hope I can share my knowledge and experiences to help create your special event. </Text>
                    </Center>
                </Box>

            :

                <Box py={100}>
                    <Center py={[7,16]}>
                        <Text fontSize={70} textStyle={"secondary"} color="black" pr={10}>About Us</Text>
                        <Image src={about} height={["10vh","15vh"]} />
                    </Center>
                    <div style={{ maxWidth: '80%', margin: '0 auto' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{ flex: 1, textAlign: 'center' }}>
                                <VStack verticalAlign="center">
                                    <Text color="black" pr={3}>We are a small event hiring business based in South- East Melbourne. With our design backgrounds we have always wanted to start a little business. Which we are passionate about where we can express ourselves creatively and share our love for helping, celebrating and making someone's day unforgettable. We highly value capturing the little memories and bringing joy to your celebrations. We look forward to bringing these special moments to you 😊</Text>
                                    <br/>
                                    <Text fontSize={20} color="black" pr={3}> <b>Savini</b></Text>
                                    <Text color="black" pr={3}>I am a current Master of Architecture student, specialising in software design, 3D model making and interior decor. I’ve worked with a number of organisations that value client and business relationships. My love for the world of creativity has flourished into this small business of ours. </Text>
                                    <br/>
                                    <Text fontSize={20} color="black" pr={3}> <b>Sandra</b></Text>
                                    <Text color="black" pr={3}>I am a visual arts teacher that has a strong passion for creativity and helping others. I’ve worked and volunteered in a number of different events and art programs, in which I hope I can share my knowledge and experiences to help create your special event. </Text>
                                </VStack>
                            </div>
                            <Box px={5} />
                            <div style={{ flex: 1, textAlign: 'center', alignSelf: 'center' }}>
                                <Image src={aboutTwo} borderRadius={'lg'} height={["10vh","70vh"]} />
                            </div>
                        </div>
                    </div>

                    
                </Box>
            }
            </main>
        </Box>
        </div>



    )
}

export default About;
