import {Box, Image, Flex, Text, Spacer, Center, HStack} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import { useScrollDirection } from '../hooks/scrollHook';
import logo from '../images/logo.png';
import { pushRotate as Menu } from 'react-burger-menu';
import ImageFlip from "../components/imageFlip";

import E4E2 from "../images/E4E-website-2.jpg";
import E4E3 from "../images/E4E-website-3.jpg";


type headerProps = {
    activePage: string,
}


export const Header:React.FC<headerProps> = (props) => {

    const getWindowDimensions = () => {
        const { innerWidth: width} = window;
        return width;
    }

    const [windowWidth, setWindowWidth] = useState(getWindowDimensions());
    const [mobile, setMobile] = useState(false);
    const [sideMenuOpen, setSideMenuOpen] = useState(false);

    const showHeader = () => {
        if(sideMenuOpen) {
            setSideMenuOpen(false)
        }else {
            setSideMenuOpen(true)
        }
    };
    const hideHeader = () => setSideMenuOpen(false);

    const scrollDirection = useScrollDirection({sideMenuOpen});


    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
          if (window.innerWidth < 480) {
            setMobile(true);
          } else {
            setMobile(false);
          }
        };
        window.addEventListener('resize', handleResize);
        window.addEventListener('load', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('load', handleResize);
            }
      }, []);
    

    return (
        <>
            <Box className={`header ${ scrollDirection === "down" ? "hide" : "show"}`} zIndex={999}>
                <Center bg={'#f2e076'} color="black">
                    <Link to="/" >
                        {windowWidth < 1000 ?
                            <Image src={logo} height="7vh"  />
                        :
                            <Image src={logo} height="15vh"  />
                        }
                    </Link>
                </Center>
            
            {windowWidth < 1000 ?
                <>
                    <Box pt={"0vh"}></Box>
                    <Menu onOpen={showHeader} onClose={hideHeader} isOpen={sideMenuOpen} pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" } >
                        <Link to="/">HOME</Link>
                        <Link to="/about">ABOUT US</Link>
                        <Link to="/walls">TO HIRE</Link>
                        <Link to="/addons">ADD ONS</Link>
                        <Link to="/faq">FAQ</Link>
                        <Link to="/book">BOOK NOW</Link>
                        <Link to="/contact">CONTACT</Link>
                    </Menu>
                </>

                :
                <>
                {windowWidth < 1200 ? 
                    <Box bg={"white"} className={`header1 ${ scrollDirection === "down" ? "hide" : "show"}`} zIndex={998}>
                        <Center mt={0} >
                            <Link to="/">
                                {props.activePage == "HOME" ? 

                                    <Box px={[2,31]} fontSize={[11,14]} py={9.1} bg="#f2e076" color="black"  >
                                        HOME
                                    </Box>
                                :
                                    <Box px={[2,31]} fontSize={[11,14]} py={9.1} color="black" _hover={{ bg: "#f2e076" , color:"black" }} >
                                        <Text>HOME</Text>
                                    </Box>
                                }
                            </Link>
                            <Link to="/about">
                                {props.activePage == "ABOUT" ? 
                                    <Box px={[2,31]} fontSize={[11,14]} py={9.1} bg="#f2e076" color="black"  >
                                        <Text whiteSpace={'nowrap'}>ABOUT US</Text>
                                    </Box>
                                    :
                                    <Box px={[2,31]} fontSize={[11,14]} py={9.1} color="black" _hover={{ bg: "#f2e076" , color:"black" }} >
                                        <Text whiteSpace={'nowrap'}>ABOUT US</Text>
                                    </Box>
                                }
                            </Link>
                            <Link to="/walls">
                                {props.activePage == "WALLS" ? 
                                    <Box px={[2,31]} fontSize={[11,14]} py={9.1} bg="#f2e076" color="black"  >
                                        TO HIRE
                                    </Box>
                                    :
                                    <Box px={[2,31]} fontSize={[11,14]} py={9.1} color="black" _hover={{ bg: "#f2e076" , color:"black" }} >
                                        <Text >TO HIRE</Text>
                                    </Box>
                                }
                            </Link>
                            <Link to="/addons">
                                {props.activePage == "ADDONS" ? 
                                    <Box px={[2,31]} fontSize={[11,14]} py={9.1} bg="#f2e076" color="black"  >
                                        ADD ONS
                                    </Box>
                                    :
                                    <Box px={[2,31]} fontSize={[11,14]} py={9.1} color="black" _hover={{ bg: "#f2e076" , color:"black" }} >
                                        <Text >ADD ONS</Text>
                                    </Box>
                                }
                            </Link>
                            <Link to="/faq">
                                {props.activePage == "FAQ" ? 
                                    <Box px={[2,31]} fontSize={[11,14]} py={9.1} bg="#f2e076" color="black"  >
                                        FAQ
                                    </Box>
                                    :
                                    <Box px={[2,31]} fontSize={[11,14]} py={9.1} color="black" _hover={{ bg: "#f2e076" , color:"black" }} >
                                        <Text >FAQ</Text>
                                    </Box>
                                }
                            </Link>
                            <Link to="/book">
                                {props.activePage == "BOOK" ? 
                                    <Box px={[2,31]} fontSize={[11,14]} py={9.1} bg="#f2e076" color="black"  >
                                        <Text whiteSpace={'nowrap'}>BOOK NOW</Text>
                                    </Box>
                                    :
                                    <Box px={[2,31]} fontSize={[11,14]} py={9.1} color="black" _hover={{ bg: "#f2e076" , color:"black" }} >
                                        <Text whiteSpace={'nowrap'}>BOOK NOW</Text>
                                    </Box>
                                }
                            </Link>
                            <Link to="/contact">
                                {props.activePage == "CONTACT" ? 
                                    <Box px={[2,31]} fontSize={[11,14]} py={9.1} bg="#f2e076" color="black"  >
                                        CONTACT
                                    </Box>
                                    :
                                    <Box px={[2,31]} fontSize={[11,14]} py={9.1} color="black" _hover={{ bg: "#f2e076" , color:"black" }} >
                                        CONTACT
                                    </Box>
                                }
                            </Link>
                        </Center>
                    </Box>
                    :
                    <Box bg={"white"} className={`header1 ${ scrollDirection === "down" ? "hide" : "show"}`} zIndex={998}>
                        <Center mt={0} >
                        <Link to="/">
                                {props.activePage == "HOME" ? 

                                    <Box px={[2,41]} fontSize={[11,14]} py={9.1} bg="#f2e076" color="black"  >
                                        HOME
                                    </Box>
                                :
                                    <Box px={[2,41]} fontSize={[11,14]} py={9.1} color="black" _hover={{ bg: "#f2e076" , color:"black" }} >
                                        <Text>HOME</Text>
                                    </Box>
                                }
                            </Link>
                            <Link to="/about">
                                {props.activePage == "ABOUT" ? 
                                    <Box px={[2,41]} fontSize={[11,14]} py={9.1} bg="#f2e076" color="black"  >
                                        <Text whiteSpace={'nowrap'}>ABOUT US</Text>
                                    </Box>
                                    :
                                    <Box px={[2,41]} fontSize={[11,14]} py={9.1} color="black" _hover={{ bg: "#f2e076" , color:"black" }} >
                                        <Text whiteSpace={'nowrap'}>ABOUT US</Text>
                                    </Box>
                                }
                            </Link>
                            <Link to="/walls">
                                {props.activePage == "WALLS" ? 
                                    <Box px={[2,41]} fontSize={[11,14]} py={9.1} bg="#f2e076" color="black"  >
                                        TO HIRE
                                    </Box>
                                    :
                                    <Box px={[2,41]} fontSize={[11,14]} py={9.1} color="black" _hover={{ bg: "#f2e076" , color:"black" }} >
                                        <Text >TO HIRE</Text>
                                    </Box>
                                }
                            </Link>
                            <Link to="/addons">
                                {props.activePage == "ADDONS" ? 
                                    <Box px={[2,41]} fontSize={[11,14]} py={9.1} bg="#f2e076" color="black"  >
                                        ADD ONS
                                    </Box>
                                    :
                                    <Box px={[2,41]} fontSize={[11,14]} py={9.1} color="black" _hover={{ bg: "#f2e076" , color:"black" }} >
                                        <Text >ADD ONS</Text>
                                    </Box>
                                }
                            </Link>
                            <Link to="/faq">
                                {props.activePage == "FAQ" ? 
                                    <Box px={[2,41]} fontSize={[11,14]} py={9.1} bg="#f2e076" color="black"  >
                                        FAQ
                                    </Box>
                                    :
                                    <Box px={[2,41]} fontSize={[11,14]} py={9.1} color="black" _hover={{ bg: "#f2e076" , color:"black" }} >
                                        <Text >FAQ</Text>
                                    </Box>
                                }
                            </Link>
                            <Link to="/book">
                                {props.activePage == "BOOK" ? 
                                    <Box px={[2,41]} fontSize={[11,14]} py={9.1} bg="#f2e076" color="black"  >
                                        <Text whiteSpace={'nowrap'}>BOOK NOW</Text>
                                    </Box>
                                    :
                                    <Box px={[2,41]} fontSize={[11,14]} py={9.1} color="black" _hover={{ bg: "#f2e076" , color:"black" }} >
                                        <Text whiteSpace={'nowrap'}>BOOK NOW</Text>
                                    </Box>
                                }
                            </Link>
                            <Link to="/contact">
                                {props.activePage == "CONTACT" ? 
                                    <Box px={[2,41]} fontSize={[11,14]} py={9.1} bg="#f2e076" color="black"  >
                                        CONTACT
                                    </Box>
                                    :
                                    <Box px={[2,41]} fontSize={[11,14]} py={9.1} color="black" _hover={{ bg: "#f2e076" , color:"black" }} >
                                        CONTACT
                                    </Box>
                                }
                            </Link>
                        </Center>
                    </Box>
                }
                </>
            }
            </Box>
        </>

    )

}
