import React from 'react';
import './App.css';
import { Box, ChakraProvider } from '@chakra-ui/react';
import {BrowserRouter as Router,Routes,Route} from "react-router-dom";
import Home from './pages/home'
import Walls from './pages/walls'
import Faq from './pages/faq'
import Contact from './pages/contact'
import Book from './pages/book'
import About from "./pages/about"
import AddOns from "./pages/addons"
import theme from "./theme"

function App() {
  return (
    <ChakraProvider theme={theme} >
      <Box bgColor="white" >
        <Router>
          <Routes>
            <Route path="/walls" element={ <Walls /> } />
            <Route path="/addons" element={ <AddOns /> } />
            <Route path="/faq" element={ <Faq /> } />
            <Route path="/contact" element={ <Contact /> } />
            <Route path="/book" element={ <Book /> } />
            <Route path="/about" element={ <About /> } />
            <Route path="/" element={ <Home /> } />
          </Routes>
        </Router>
      </Box>

  </ChakraProvider> 
  );
}

export default App;
