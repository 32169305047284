import { Box, Button, Center, Flex,Grid,Image, Spacer, Text, VStack} from '@chakra-ui/react';
import { Link } from "react-router-dom";
import ImageFlip from "../components/imageFlip";
import {Header} from "../components/header";
import CopyToClipboardButton from "../components/copyToClipboardButton";
import logo from '../images/logo.png';

import DR_1 from "../images/DR_1.jpg";
import DR_2 from "../images/DR_2.jpg";
import DR_3 from "../images/DR_3.jpg";
import DR_4 from "../images/DR_4.jpg";
import DR_5 from "../images/DR_5.jpg";
import DR_6 from "../images/DR_6.jpg";

import WF_1 from "../images/E4E-website-1.jpg";
import WF_2 from "../images/E4E-website-2.jpg";
import WF_3 from "../images/E4E-website-3.jpg";
import WF_4 from "../images/E4E-website-4.jpg";
import WF_5 from "../images/E4E-website-5.jpg";
import WF_6 from "../images/E4E-website-6.jpg";

import third_row_1_f from "../images/3_1_f-min.jpg"
import third_row_1_b from "../images/3_1_b-min.jpg"
import third_row_2_f from "../images/3_2_f-min.jpg"
import third_row_2_b from "../images/3_2_b-min.jpg"
import third_row_3_f from "../images/3_3_f-min.jpg"
import third_row_3_b from "../images/3_3_b-min.jpg"


import { useEffect, useState } from 'react';

import { pushRotate as Menu } from 'react-burger-menu';
import {useScrollDirection} from "../hooks/scrollHook";
import { SocialIcon } from 'react-social-icons';


const Home = () => {
    const getWindowDimensions = () => {
        const { innerWidth: width} = window;
        return width;
    }
    const [windowWidth, setWindowWidth] = useState(getWindowDimensions());
    const [mobile, setMobile] = useState(false);



    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
          if (window.innerWidth < 1000) {
            setMobile(true);
          } else {
            setMobile(false);
          }
        };
        window.addEventListener('resize', handleResize);
        window.addEventListener('load', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('load', handleResize);
            }
      }, []);
    

    return (
        <div id="outer-container">

            <Header activePage={"HOME"} />
            
            
            <main id="page-wrap">
            {windowWidth < 1000 ? 
                <>
                    <Box pb={10} />
                    <Box pt={[0,50]}>
                        <Center>
                            <ImageFlip frontImageUrl={WF_2} backImageUrl={DR_2} reverse={true} mobile={mobile} interval={5000} delay={1000} />
                            <ImageFlip frontImageUrl={DR_1} backImageUrl={WF_4} reverse={true} mobile={mobile} interval={5000} delay={0} />
                        </Center>
                        <Center pt={[2,2]}>
                            <ImageFlip frontImageUrl={DR_3} backImageUrl={WF_3} reverse={true} mobile={mobile} interval={5000} delay={0} />
                            <ImageFlip frontImageUrl={WF_1} backImageUrl={DR_5} reverse={true} mobile={mobile} interval={5000} delay={1000} />
                        </Center>
                        <Center pt={[2,2]}>
                            <ImageFlip frontImageUrl={WF_6} backImageUrl={DR_6} reverse={true} mobile={mobile} interval={5000} delay={1000} />
                            <ImageFlip frontImageUrl={DR_4} backImageUrl={WF_5} reverse={true} mobile={mobile} interval={5000} delay={0} />
                        </Center>
                        <Center pt={[2,2]}>
                            <ImageFlip frontImageUrl={third_row_3_b} backImageUrl={third_row_3_f} reverse={true} mobile={mobile} interval={5000} delay={0} />
                            <ImageFlip frontImageUrl={third_row_1_f} backImageUrl={third_row_2_f} reverse={true} mobile={mobile} interval={5000} delay={1000} />
                        </Center>
                    </Box>
                </>
                :
                <>
                    <Box pb={20} />
                    <Box pt={[25,120]}>
                        <Center>
                            <ImageFlip frontImageUrl={DR_3} backImageUrl={WF_4} reverse={true} mobile={mobile} interval={5000} delay={0} />
                            <ImageFlip frontImageUrl={WF_2} backImageUrl={DR_5} reverse={true} mobile={mobile} interval={5000} delay={1000} />
                            <ImageFlip frontImageUrl={DR_1} backImageUrl={WF_3} reverse={true} mobile={mobile} interval={5000} delay={0} />
                        </Center>
                        <Center pt={[2,2]}>
                            <ImageFlip frontImageUrl={WF_1} backImageUrl={DR_2} reverse={true} mobile={mobile} interval={5000} delay={1000} />
                            <ImageFlip frontImageUrl={DR_4} backImageUrl={WF_5} reverse={true} mobile={mobile} interval={5000} delay={0} />
                            <ImageFlip frontImageUrl={WF_6} backImageUrl={DR_6} reverse={true} mobile={mobile} interval={5000} delay={1000} />
                        </Center>
                        <Center pt={[2,2]}>
                            <ImageFlip frontImageUrl={third_row_1_b} backImageUrl={third_row_1_f} reverse={true} mobile={mobile} interval={5000} delay={2} />
                            <ImageFlip frontImageUrl={third_row_2_b} backImageUrl={third_row_2_f} reverse={true} mobile={mobile} interval={5000} delay={1000} />
                            <ImageFlip frontImageUrl={third_row_3_b} backImageUrl={third_row_3_f} reverse={true} mobile={mobile} interval={5000} delay={2} />
                        </Center>
                    </Box>
                </>
            }
                <Center pt={35} pb={0}>
                    <SocialIcon bgColor="#f2e077" url="https://www.instagram.com/eye4eventsbyss/" />
                </Center>
                <Center>
                    <Text pl={0} fontSize={20} textAlign="center" maxW={"90%"} color="black">
                        <a href="https://www.instagram.com/eye4eventsbyss/">Check out our instagram for more photos</a>
                    </Text>
                </Center>

            {windowWidth < 1000 ? 
            <Center pt={[25,50]} pb={100}>
                <section id="testimonialsMobile">
                    <h2 style={{color:"black", fontSize:"24px"}} >What Our Customers Are Saying</h2>

                    <div className="testimonialMobile">
                        <p>Thank you so much for making our 40th anniversary so special and beautiful. I won’t ever forget my special day! You were both so kind, always making sure I was happy with the final preparations for the day</p>

                    </div>

                    <div className="testimonialMobile">
                        <p>Amazing work done by @eye4eventsbyss! 🎉 THANK YOU GIRLS! 👏 The flower wall was perfect for my photos last night!!</p>

                    </div>

                    <div className="testimonialMobile">
                        <p>Just wanted to say a big thank you for last night! The white flamingo wall honestly looked incredible and added so much to the overall decor! Everyone loved the wall 😍 I couldn’t recommend you both enough. I will definitely be hiring from you guys again 💓 </p>

                    </div>
                </section>
            </Center>
            :
            <Center pt={[25,50]} pb={100}>
                <section id="testimonialsDesktop">
                    <h2 style={{color:"black", fontSize:"24px"}} >What Our Customers Are Saying</h2>

                    <div className="testimonialDesktop">
                        <p>Thank you so much for making our 40th anniversary so special and beautiful. I won’t ever forget my special day! You were both so kind, always making sure I was happy with the final preparations for the day</p>

                    </div>

                    <div className="testimonialDesktop">
                        <p>Amazing work done by @eye4eventsbyss! 🎉 THANK YOU GIRLS! 👏 The flower wall was perfect for my photos last night!!</p>

                    </div>

                    <div className="testimonialDesktop">
                        <p>Just wanted to say a big thank you for last night! The white flamingo wall honestly looked incredible and added so much to the overall decor! Everyone loved the wall 😍 I couldn’t recommend you both enough. I will definitely be hiring from you guys again 💓 </p>

                    </div>
                </section>
            </Center>
            }
            </main>

        </div>
    )
}

export default Home;
