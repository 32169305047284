import { Box, Flex,Grid,Image, Spacer, Text, VStack, Center} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Header } from '../components/header';



import addon2a from "../images/addon2a.jpg";
import addon2b from "../images/addon2b.jpg";
import addon2c from "../images/addon2c.jpg";
import comingSoon from "../images/comingSoon.png";
import flute_add_on_1 from "../images/flute_add_on_1.jpg";
import flute_add_on_2 from "../images/flute_add_on_2.jpg";
import flute_add_on_3 from "../images/flute_add_on_3.jpg";
import name_tag_add_on_1 from "../images/name_tag_add_on_1.jpg";
import name_tag_add_on_2 from "../images/name_tag_add_on_2.jpg";
import name_tag_add_on_3 from "../images/name_tag_add_on_3.jpg";
import hooray_add_on_1 from "../images/hooray_add_on_1.jpg";
import hooray_add_on_2 from "../images/hooray_add_on_2.jpg";
import hooray_add_on_3 from "../images/hooray_add_on_3.jpg";
import AddOn from "../components/addon";


const AddOns = () => {

    const getWindowDimensions = () => {
        const { innerWidth: width} = window;
        return width;
    }
    const [windowWidth, setWindowWidth] = useState(getWindowDimensions());
    const [mobile, setMobile] = useState(false);


    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
          if (window.innerWidth < 480) {
            setMobile(true);
          } else {
            setMobile(false);
          }
        };
        window.addEventListener('resize', handleResize);
        window.addEventListener('load', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('load', handleResize);
            }
      }, []);

    return (
        <>
        <div id="outer-container">
            <Header activePage={"ADDONS"} />
            <main id="page-wrap">
            <Box pt={[15,120]} />
            <Box px={2}>
                <Center pt={[0,50]}>
                    <Text textStyle={"secondary"} fontSize={[70,80]} color={"black"}>
                        Add Ons
                    </Text>
                </Center>

                <Center pt={[0,30]} px={1}>
                    <Text pb={0} color={"black"} textAlign={"center"}>
                        The White Flamingo Wall & Drunk Rhino wall have the ability to be transformed into a variety of variations. 
                    </Text>
                </Center>
                <br/>
                <Center>
                    <Text pb={3} color={"black"} px={1} textAlign={"center"}>
                    If you have any fun ideas to try on the White Flamingo & Drunk Rhino wall for your event, please enquire and we can make your vision come to life. 
                    </Text>
                </Center>
            </Box>
            <AddOn 
                name={"Customised Name Tags"}
                description={"*Please enquire which colour and design you are interested in."}
                price1={"$40"}
                img1={name_tag_add_on_2}
                img2={name_tag_add_on_3}
                img3={name_tag_add_on_1}
            />
            <AddOn 
                name={"Customised grazing and/or lollie cones"}
                description={"*Please enquire which colour and design you are interested in. You can request for personalised stickers. "}
                price1={"$50 - Cones "}
                price2={"$15 - Stickers"}
                img1={addon2a}
                img2={addon2b}
                img3={addon2c}
            />
            <AddOn 
                name={"Hip Hip Hooray Sign"}
                description={"40x40cm wooden sign in glamorous gold. Can be stuck to the bottom of the champagne wall or free standing depending on flute arrangement."}
                price1={"$10 "}
                img1={hooray_add_on_1}
                img2={hooray_add_on_2}
                img3={hooray_add_on_3}
            />
            <AddOn 
                name={"Champagne Flute"}
                description={"Unbreakable hard plastic that is BPA free"}
                price1={"$1 per flute "}
                img1={flute_add_on_1}
                img2={flute_add_on_2}
                img3={flute_add_on_3}
            />
            <AddOn 
                name={"Customised easel with signage "}
                description={"*Please enquire which design including which colour, text and font you are interested in. Additional costs may apply depending on the design you’re after."}
                price1={"Customised easel with acrylic board  signage - $90"}
                price2={"Flower background - $10"}
                price3={"Flower arrangement  - $30"}
                img1={comingSoon}
                img2={comingSoon}
                img3={comingSoon}
            />
            <AddOn 
                name={"Flower Arrangement"}
                description={"*Please enquire which design including which arrangement you would like: "}
                price1={"Customised easel with acrylic board  signage - $90"}
                price2={"Pastel Orange - $"}
                price3={"Tones of Purple - $"}
                img1={comingSoon}
                img2={comingSoon}
            />

            </main>
        </div>
        </>
    )
}

export default AddOns;
