import { Box, Flex,Grid,Image, Spacer, Text, VStack, Center} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import MobileImageCarouselAddOns from "../components/mobileImageCarouselAddOns";


type addonProps = {
    img1:string,
    img2?:string|undefined,
    img3?:string|undefined,
    name:string,
    description:string,
    extraDescription?:string|undefined,
    price1:string,
    price2?:string|undefined,
    price3?:string|undefined
}


const AddOn:React.FC<addonProps> = (props) => {

    const getWindowDimensions = () => {
        const { innerWidth: width} = window;
        return width;
    }
    const [windowWidth, setWindowWidth] = useState(getWindowDimensions());
    const [mobile, setMobile] = useState(false);

    const imgProps = {
        img1: props.img1,
        img2: props.img2,
        img3: props.img3
    }

    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
          if (window.innerWidth < 480) {
            setMobile(true);
          } else {
            setMobile(false);
          }
        };
        window.addEventListener('resize', handleResize);
        window.addEventListener('load', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('load', handleResize);
            }
      }, []);

    return (
        <>  
            <Box px={2}>
            <Center pt={[10,30]}>
                <Text textStyle={"secondary"} textAlign="center" fontSize={[30,60]} color={"black"}>
                    {props.name}
                </Text>
            </Center>
            <Center pb={5}>
                <Text color={"black"} textAlign={"center"}>
                    {props.description}
                </Text>
            </Center>
            {props.extraDescription &&
            <Center pb={5}>
                <Text color={"black"} textAlign={"center"}>
                    {props.extraDescription}
                </Text>
            </Center>
            }
            <Center pb={5}>
                <Text textAlign={"center"} color={"black"} >{props.price1}</Text> 
            </Center>
            {props.price2 &&
            <Center pb={5}>
                <Text textAlign={"center"} color={"black"} >{props.price2}</Text> 
            </Center>
            }
            {props.price3 &&
            <Center pb={5}>
                <Text textAlign={"center"} color={"black"} >{props.price3}</Text> 
            </Center>
            }
            </Box>
            {windowWidth < 1000 ? 
            <>
            {/* { (props.img1 && props.img2 && props.img3) ? 
                <MobileImageCarouselAddOns img1={props.img1} img2={props.img2} img3={props.img3} />
                :
                (props.img1 && props.img2) ? 
                <MobileImageCarouselAddOns img1={props.img1} img2={props.img2} />
                :
                props.img1 && <MobileImageCarouselAddOns img1={props.img1}  />
            } */}
            <MobileImageCarouselAddOns imgProps={imgProps} />
            {/* { props.img1 && props.img2 && <MobileImageCarouselAddOns img1={props.img1} img2={props.img2} /> }
            { props.img1 && <MobileImageCarouselAddOns img1={props.img1}  /> } */}

            
            {/* <Center>
                <Image py={3} src={props.img1} borderRadius={'lg'} height={"98vw"} />
            </Center>
            <Center>
                {props.img2 && <Image py={3} src={props.img2} borderRadius={'lg'} height={"98vw"} /> }
            </Center>  
            <Center>
                {props.img3 && <Image py={3} src={props.img3} borderRadius={'lg'} height={"98vw"} /> }
            </Center> */}
            </>
            :
            
            <Center>
                <Image src={props.img1} borderRadius={'lg'} height={"30vw"} px={2} />
                {props.img2 && <Image src={props.img2} borderRadius={'lg'} height={"30vw"} px={2} /> }
                {props.img3 && <Image src={props.img3} borderRadius={'lg'} height={"30vw"} px={2} /> }
            </Center>
            }
        </>
    )
}

export default AddOn;
