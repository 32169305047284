import React, { useEffect, useState } from 'react';
import {Box, Button, Center, Image, Text, VStack} from '@chakra-ui/react';
import { Header } from '../components/header';
import  MobileImageCarousel  from '../components/mobileImageCarousel';
import  MoreInfoPrice  from './moreInfoPrice';
import  MoreInfoAverageMarket  from './moreInfoAverageMarket';
import { Link } from 'react-router-dom';


type wallProps = {
    img1:string,
    img2:string,
    img3:string,
    img4:string,
    wallName:string,
    wallDescription:string,
    wallPrice:string,
    averagePrice:string,
    wallExtra1?:string|undefined,
    extra1padding?:boolean|undefined,
    wallExtra2?:string|undefined,
    viewAddOns?: boolean|undefined,
    wallExtra3?:string|undefined,
    wallExtra4?:string|undefined,
    wallExtra5?:string|undefined,
}
const Wall:React.FC<wallProps> = (props) => {

    const getWindowDimensions = () => {
        const { innerWidth: width} = window;
        return width;
    }
    const [windowWidth, setWindowWidth] = useState(getWindowDimensions());
    const [mobile, setMobile] = useState(false);


    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
          if (window.innerWidth < 480) {
            setMobile(true);
          } else {
            setMobile(false);
          }
        };
        window.addEventListener('resize', handleResize);
        window.addEventListener('load', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('load', handleResize);
            }
      }, []);

  return (
    <Box pb={100}>
        {windowWidth < 1000 ? 
        <>
            <Text textStyle={"secondary"} textAlign={"center"} fontSize={50} color="black" pt={2}> '{props.wallName}'</Text>

            <div style={{ maxWidth: '85%', margin: '0 auto' }}>
                <br />
                <Text textAlign={"center"} color={"black"} >{props.wallDescription}</Text>
                <br />
                {props.wallExtra1 && <Text textAlign={"center"} color={"black"} >{props.wallExtra1}</Text> }
                {props.extra1padding && <br/> }
                {props.wallExtra2 && <Text textAlign={"center"} color={"black"} >{props.wallExtra2}</Text> }

                {props.viewAddOns && 
                    <Link to="/addons">
                        <Center py={4}>
                            <Button size={"m"} py={1} fontSize={"10px"} bg={"#f2e076"} px={3} color="black">Click here to view ADD ONS</Button>
                        </Center>
                    </Link>

                }

                {props.wallExtra3 && <Text textAlign={"center"} color={"black"} >{props.wallExtra3}</Text> }
                {props.wallExtra4 && <Text textAlign={"center"} color={"black"} >{props.wallExtra4}</Text> }
                {props.wallExtra5 && <Text textAlign={"center"} color={"black"} >{props.wallExtra5}</Text> }
                <br />
            </div>
            <MobileImageCarousel wallType={props.wallName} />
            <br/>
            <Text textAlign={"center"} color={"black"} >Our Price: {props.wallPrice}</Text>
            <MoreInfoPrice />
            <br />

            <Text textAlign={"center"} color={"black"} >Current Market Average Price: {props.averagePrice}</Text>
            <MoreInfoAverageMarket />
            <br/>


        </>
        :
        <>
            <div style={{ maxWidth: '70%', margin: '0 auto' }}>
                <Text textStyle={"secondary"} textAlign={"center"} fontSize={70} color="black" pt={30}> '{props.wallName}'</Text>
                <Text textAlign={"center"} color={"black"} >{props.wallDescription}</Text>
                <br />
                {props.wallExtra1 && <Text textAlign={"center"} color={"black"} >{props.wallExtra1}</Text> }
                {props.extra1padding && <br/> }
                {props.wallExtra2 && <Text textAlign={"center"} color={"black"} >{props.wallExtra2}</Text> }
                {props.viewAddOns && 
                    <Link to="/addons">
                        <Center py={6}>
                            <Button size={"xl"} py={2} fontSize={"12px"} bg={"#f2e076"} px={3} color="black">Click here for ADD ONS</Button>
                        </Center>
                    </Link>

                }
                {props.wallExtra3 && <Text textAlign={"center"} color={"black"} >{props.wallExtra3}</Text> }
                {props.wallExtra4 && <Text textAlign={"center"} color={"black"} >{props.wallExtra4}</Text> }
                {props.wallExtra5 && <Text textAlign={"center"} color={"black"} >{props.wallExtra5}</Text> }
                <br />




            </div>
            <Center>
                <Image src={props.img1} borderRadius={'lg'} height={"30vw"} px={2} />
                <Image src={props.img2} borderRadius={'lg'} height={"30vw"} px={2} />
                <Image src={props.img3} borderRadius={'lg'} height={"30vw"} px={2} />
                <Image src={props.img4} borderRadius={'lg'} height={"30vw"} px={2} />
            </Center>
            <Box py={25} />
            <Center>
                
                <VStack>
                    <Text textAlign={"center"} color={"black"} >Our Price: {props.wallPrice}</Text>
                    <MoreInfoPrice />
                    <br />

                    <Text textAlign={"center"} color={"black"} >Current Market Average Price: {props.averagePrice}</Text>
                    <MoreInfoAverageMarket />

                    
                </VStack>
            </Center>
        </>
        }
    </Box>

  );
}

export default Wall;