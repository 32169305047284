import { Box, Flex,Grid,Image, Spacer, Text, VStack, Center, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Button} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Header } from '../components/header';
import logo from '../images/logo.png';


const Faq = () => {
    
    return (
        <div id="outer-container">
        <Box pb={700}>
            <Header activePage={"FAQ"} />
            <main id="page-wrap">
            <Box>
                <Center pt={[30,150]}>
                    <Text textStyle={"secondary"} fontSize={[40,80]} color={"black"}>
                        Frequently Asked Questions
                    </Text>
                </Center>
                <Box pt={10} pb={100} px={["2vw","25vw"]}>
                    <Accordion allowToggle bg="white" borderColor="white" >
                        <AccordionItem>
                            <h2>
                            <AccordionButton  bg="#f2e076"  _expanded={{ bg: '#f2e076', color: 'black' }}>
                                <Box flex='1' textAlign='left' color={"black"} >
                                    How do I book? 
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={8} borderColor="pink" >
                                <Text pb={3} color={"black"}>
                                You can book by sending us an email, direct message or through a simple phone call. We’ll then discuss payment details to proceed with the hire and send you a confirmation email to finalise your booking.
                                </Text>
                                <Center>
                                    <Link to="/book">
                                        <Button bg="#f2e076" color={"black"}>
                                            Book Now
                                        </Button>
                                    </Link>
                                </Center>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                            <AccordionButton bg="#f2e076" _expanded={{ bg: '#f2e076', color: 'black' }}>
                                <Box flex='1' textAlign='left' color={"black"}>
                                    How long does it take to set up and pack up? 
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} color={"black"}>
                            Each set up is unique, therefore the duration time may vary. However, most setups take approximately 1-1.5hrs to set up and to pack up. You can find out more information on the duration times specific to the set up you're interested in hiring in our ‘to hire’ page. 
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                            <AccordionButton bg="#f2e076" _expanded={{ bg: '#f2e076', color: 'black' }}>
                                <Box flex='1' textAlign='left' color={"black"}>
                                    Is there an extra cost depending on the location? 
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} color={"black"}>
                            After our initial conversation with our client, we will consult and configure a cost that includes all applicable fees including extra charges for distance and add ons. There will be an extra fee if the location is more than 15 km from where we are based.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                            <AccordionButton bg="#f2e076" _expanded={{ bg: '#f2e076', color: 'black' }}>
                                <Box flex='1' textAlign='left' color={"black"}>
                                    Can the wall be placed inside or outside?
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} color={"black"}>
                            Each set up has different possibilities. Unfortunately our ‘White flamingo Wall’  isn't compatible outdoors due to wind, rain etc. Therefore the wall can only be hired for indoor or under a covered area. However, good news our ‘Drunk Rhino wall’ can be hired for both indoor and outdoor locations! 
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                            <AccordionButton bg="#f2e076" _expanded={{ bg: '#f2e076', color: 'black' }}>
                                <Box flex='1' textAlign='left' color={"black"}>
                                    Can I move the wall?
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} color={"black"}>
                                Unfortunately the walls are built in an intricate manner which involves screws and timber slotting. Therefore the wall is not mobile and cannot be moved. It will have to stay stationary from when we set it up until we pack it up.
                            </AccordionPanel>
                        </AccordionItem>

                        {/* <AccordionItem>
                            <h2>
                            <AccordionButton bg="#f2e076" _expanded={{ bg: '#f2e076', color: 'black' }}>
                                <Box flex='1' textAlign='left' color={"black"}>
                                    Do I need to pay a bond? 
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} color={"black"}>
                                There is a bond of $100 which will be given back to you if there are no damages to the wall present after it has been hired. The cost we’ll keep will be equivalent to the damages done.
                            </AccordionPanel>
                        </AccordionItem> */}

                        <AccordionItem>
                            <h2>
                            <AccordionButton bg="#f2e076" _expanded={{ bg: '#f2e076', color: 'black' }}>
                                <Box flex='1' textAlign='left' color={"black"}>
                                How long can I hire for? 
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} color={"black"}>
                                The hire period lasts for a whole day. Regardless of when your event starts on your chosen day, the latest we can pick up the wall is at midnight.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                            <AccordionButton bg="#f2e076" _expanded={{ bg: '#f2e076', color: 'black' }}>
                                <Box flex='1' textAlign='left' color={"black"}>
                                    When is the latest I can book?
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} color={"black"}>
                                We recommend booking or letting us know at least 1-2 weeks prior to your event. But we are pretty flexible so you’re welcome to email, call or send us a message even if the event is a couple days before. But in saying that you are not guaranteed a hire as we might have other bookings. To secure your booking, the earlier you let us know the better.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                            <AccordionButton bg="#f2e076" _expanded={{ bg: '#f2e076', color: 'black' }}>
                                <Box flex='1' textAlign='left' color={"black"}>
                                    Can I cancel my booking?
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} color={"black"}>
                                Yes, you can cancel a booking and will receive any payments you’ve made to us. However, if cancellations are made less than 72 hours before your scheduled event hire, you will be charged a $50 cancellation fee. 
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                            <AccordionButton bg="#f2e076" _expanded={{ bg: '#f2e076', color: 'black' }}>
                                <Box flex='1' textAlign='left' color={"black"}>
                                    How and when can I change my booking?
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} color={"black"} >
                                You can call, email or message us to change your booking (our details are in the ‘contact’ page). If you wish to postpone your hire, you must give a 72 hour notice before your original scheduled hire. If you wish to have your event hire earlier, message or call us as soon as you have your new date planned. 
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <h2>
                            <AccordionButton bg="#f2e076" _expanded={{ bg: '#f2e076', color: 'black' }}>
                                <Box flex='1' textAlign='left' color={"black"}>
                                    How and when do I have to pay?
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} color={"black"}>
                                After you make your booking with us, we will send a confirmation email with the pricing and any additional details discussed. Thereafter you can transfer the set amount to us to secure your booking. 
                            </AccordionPanel>
                        </AccordionItem>


                    </Accordion>
                </Box>
            </Box>
            </main>

        </Box>

        </div>

    )
}

export default Faq;
