import { useDisclosure, Button, Collapse, Box, Text, Center } from '@chakra-ui/react'


const MoreInfoPrice = (props:any) => {
    const { isOpen, onToggle } = useDisclosure()
  
    return (
      <>
        <Center pt={2}>
            <Button size={"xs"} py={0} fontSize={"8px"} bg={"#f2e076"} px={3} color="black" onClick={onToggle}>More info on price</Button>
        </Center>
        <Collapse in={isOpen} animateOpacity>
          <Box
            p='10px'
            color='black'
            mt='4'
            bg='#f2e076'
            rounded='md'
            shadow='md'
          >
            <Text textAlign="center" >Above is the base price of the wall however the final price may differ depending on the location and the conditions of the location eg: stairs etc.</Text>
          </Box>
        </Collapse>
      </>
    )
  }


  export default MoreInfoPrice;
  