import React, { useState } from 'react';
import { Tabs, Tab, MenuItem, Select } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import {Center, Image} from '@chakra-ui/react';


import wf1 from "../images/wf1.jpg";
import wf2 from "../images/wf2.jpg";
import wf3 from "../images/wf3.jpg";
import wf4 from "../images/wf4.jpg";

import dr1 from "../images/dr1.jpg";
import dr2 from "../images/dr2.jpg";
import dr3 from "../images/dr3.jpg";
import dr4 from "../images/dr4.jpg";

const styles = {

  root: {
    padding: '0 40px',
  },
  slideContainer: {
    padding: '0 10px',
  },


  tabs: {
    background: '#f2e076',
    color: 'black',
    
  },
  slide: {
    padding: 0,
    minHeight: 100,
    color: '#f2e076',
    
  },
  slide1: {
    backgroundColor: '#FFF',
  },
  slide2: {
    backgroundColor: '#FFF',
  },
  slide3: {
    backgroundColor: '#FFF',
  },
  slide4: {
    backgroundColor: '#FFF',
  },
};

type carouselProps = {
  wallType:string
}

const MobileImageCarousel:React.FC<carouselProps> = (props) => {
  const [index, setIndex] = useState(0);



  const handleChange = (event:any, value:any) => {
    setIndex(value);
  };

  const handleChangeIndex = (index:any) => {
    setIndex(index);
  };

  return (
    <div>
      <SwipeableViews style={styles.root} slideStyle={styles.slideContainer} index={index} onChangeIndex={handleChangeIndex}>
        <div style={Object.assign({}, styles.slide, styles.slide1)}>
            <Center>
                { props.wallType == "White Flamingo Wall" && <Image src={wf1} borderRadius={'lg'} height={"100vw"} px={0} /> }
                { props.wallType == "Drunk Rhino Wall" && <Image src={dr1} borderRadius={'lg'} height={"100vw"} px={0} /> }
            </Center>
        </div>
        <div style={Object.assign({}, styles.slide, styles.slide2)}>
            <Center>
                { props.wallType == "White Flamingo Wall" && <Image src={wf2} borderRadius={'lg'} height={"100vw"} px={0} /> }
                { props.wallType == "Drunk Rhino Wall" && <Image src={dr2} borderRadius={'lg'} height={"100vw"} px={0} /> }
            </Center>
        </div>
        <div style={Object.assign({}, styles.slide, styles.slide3)}>
            <Center>
                { props.wallType == "White Flamingo Wall" && <Image src={wf3} borderRadius={'lg'} height={"100vw"} px={0} /> }
                { props.wallType == "Drunk Rhino Wall" && <Image src={dr3} borderRadius={'lg'} height={"100vw"} px={0} /> }
            </Center>
        </div>
        <div style={Object.assign({}, styles.slide, styles.slide4)}>
            <Center>
                { props.wallType == "White Flamingo Wall" && <Image src={wf4} borderRadius={'lg'} height={"100vw"} px={0} /> }
                { props.wallType == "Drunk Rhino Wall" && <Image src={dr4} borderRadius={'lg'} height={"100vw"} px={0} /> }
            </Center>
        </div>
      </SwipeableViews>


    </div>
  );
}

export default MobileImageCarousel;