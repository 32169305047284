import { Box, Flex,Grid,Image, Spacer, Text, VStack, Center} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Header } from '../components/header';


import  Wall  from '../components/Wall';

import wf1 from "../images/wf1.jpg";
import wf2 from "../images/wf2.jpg";
import wf3 from "../images/wf3.jpg";
import wf4 from "../images/wf4.jpg";

import dr1 from "../images/dr1.jpg";
import dr2 from "../images/dr2.jpg";
import dr3 from "../images/dr3.jpg";
import dr4 from "../images/dr4.jpg";



const Walls = () => {

    return (
        <>
        <div id="outer-container">
            <Header activePage={"WALLS"} />
            <main id="page-wrap">
            <Box pt={[25,120]} />
            <Wall 
                img1={wf1} 
                img2={wf2} 
                img3={wf3} 
                img4={wf4} 
                wallName={"White Flamingo Wall"}
                wallDescription={"The white flamingo wall is made up of 7 different flower variations. Varying from roses, lilies to baby’s breath. Coupled with green contrasting leaves to form a beautiful arrangement. The flowers and the leaves complement each other to create a balanced composition. Unfortunately the wall isn't compatible outdoors due to wind, rain etc. Therefore the wall can only be hired for indoor or a space with a roof."}
                wallExtra1={"Size: 2.5m in length and 2m in height & 1m in width"}
                wallExtra2={"Set up duration: Approximately 2 hours depending on the location (for example stairs etc)"}
                wallExtra3={"Pack up duration: Approximately 1.5 hours "} 
                wallPrice={"$280"}
                averagePrice={"$377"}
            />
            <Box pt={25} />
            <Wall 
                img1={dr1} 
                img2={dr2} 
                img3={dr3} 
                img4={dr4} 
                wallName={"Drunk Rhino Wall"}
                wallDescription={"The drunk rhino wall is a champagne wall perfect to brighten up and add a little sparkle to your special event. The wall consists of up to 44 hard plastic champagne flutes accompanied by a luscious green-grass hedge background with a wooden frame encasing it."}
                wallExtra1={" The flutes are made with unbreakable hard plastic that are BPA free for a non-hazardous event, so you can enjoy your bubbly fun without a mess! Champagne and other beverages are not included. BYO only."}
                extra1padding={true}
                wallExtra2={"The Drunk Rhino wall can hold up to 44 champagne flutes. But, if you're having a more intimate gathering you can choose the 22 flute option. $1 Add On per flute."}
                viewAddOns={true}
                wallExtra3={"Size: 1.05m in length and 1.8m in height"}
                wallExtra4={"Set up duration: Approximately 1.5 hours depending on the location (for example stairs etc) "} 
                wallExtra5={"Pack up duration: Approximately 1 hour "} 
                wallPrice={"$200"}
                averagePrice={"$267"}
            />
            </main>
        </div>
        </>
    )
}

export default Walls;
