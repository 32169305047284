import { Box, Text, HStack, VStack, Center, Button} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Link} from "react-router-dom";
import logo from '../images/logo.png';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import { SocialIcon } from 'react-social-icons';
import { Header } from '../components/header';
import CopyToClipboardButton from '../components/copyToClipboardButton';

const Contact = () => {

    
    return (
        <div id="outer-container">

        <Box pb={400}>
            <Header activePage={"CONTACT"} />
            <main id="page-wrap">

            

            <Center pt={[45,140]}>
                <Text textStyle={"secondary"} fontSize={[45,80]} color="black">
                    Say Hello
                </Text>
            </Center>
            <Center pt={30}>
                <Text fontSize={20} color="black">
                    <b>Email:</b>
                </Text>
            </Center>
            <Center >
                <Text fontSize={20} color="black">
                    eye4eventsbyss@gmail.com
                </Text>
                <CopyToClipboardButton contents={"eye4eventsbyss@gmail.com"} />
            </Center>
            <Center pt={45} pb={5}>
                <Text fontSize={20} color="black">
                    <b>Text or call us:</b>
                </Text>    
            </Center>
            <Center>
                <VStack>
                    <Text fontSize={20} color="black">
                        0415 859 608
                        <CopyToClipboardButton contents={"0415859608"} />
                    </Text>
                    <Text fontSize={20} color="black">
                        0449 254 170
                        <CopyToClipboardButton contents={"0449254170"} />
                    </Text>
                    
                </VStack>
            </Center>
            <Center pt={30}>
                <Text fontSize={20} color="black">
                    <b>DM us on Instagram:</b>
                </Text>
            </Center>
            <Center pt={5} pb={100}>
                <SocialIcon bgColor="#f2e077" url="https://www.instagram.com/eye4eventsbyss/" />
                <Text pl={5} fontSize={20} color="black">
                    <a href="https://www.instagram.com/eye4eventsbyss/">@eye4eventsbyss</a>
                    <CopyToClipboardButton contents={"@eye4eventsbyss"} />
                </Text>
            </Center>
      
            </main>
        </Box>
        </div>


    )
}

export default Contact;

