import { Box, Center,Text, VStack} from '@chakra-ui/react';

import { Header } from '../components/header';
import { useEffect, useState } from 'react';
import CopyToClipboardButton from '../components/copyToClipboardButton';
import { SocialIcon } from 'react-social-icons';

const Book = () => {

    const getWindowDimensions = () => {
        const { innerWidth: width} = window;
        return width;
    }
    const [windowWidth, setWindowWidth] = useState(getWindowDimensions());
    const [mobile, setMobile] = useState(false);



    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
          if (window.innerWidth < 480) {
            setMobile(true);
          } else {
            setMobile(false);
          }
        };
        window.addEventListener('resize', handleResize);
        window.addEventListener('load', handleResize);
        handleResize()
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('load', handleResize);
            }
      }, []);
    
    return (
        <>
        <div id="outer-container">

        
            <Header activePage="BOOK" />
            <main id="page-wrap">
            <Center pt={[45,150]} pb={[25,45]} >
                <Text textStyle={"secondary"} fontSize={[55,80]} color={"black"}>
                    How to book
                </Text>
            </Center>

            <Box pb={500}>
                <Center >
                    <Text textAlign="center" fontSize={20} color="black" maxW={"80%"}>
                    Contact us via any of the methods below. Let us know which date, wall and add ons you're interested in hiring!
                    </Text>
                </Center>
                <Center pt={30}>
                    <Text fontSize={20} color="black">
                        <b>Email:</b>
                    </Text>
                </Center>
                <Center >
                    <Text fontSize={[16,20]} color="black">
                        eye4eventsbyss@gmail.com
                    </Text>
                    <CopyToClipboardButton contents={"eye4eventsbyss@gmail.com"} />
                </Center>
                <Center pt={45} pb={5}>
                    <Text fontSize={20} color="black">
                        <b>Text or call us:</b>
                    </Text>    
                </Center>
                <Center>
                    <VStack>
                        <Text fontSize={20} color="black">
                            0415 859 608
                            <CopyToClipboardButton contents={"0415859608"} />
                        </Text>
                        <Text fontSize={20} color="black">
                            0449 254 170
                            <CopyToClipboardButton contents={"0449254170"} />
                        </Text>
                        
                    </VStack>
                </Center>
                <Center pt={30}>
                    <Text fontSize={20} color="black">
                        <b>DM us on Instagram:</b>
                    </Text>
                </Center>
                <Center pt={5} pb={100}>
                    <SocialIcon bgColor="#f2e077" url="https://www.instagram.com/eye4eventsbyss/" />
                    <Text pl={5} fontSize={20} color="black">
                        <a href="https://www.instagram.com/eye4eventsbyss/">@eye4eventsbyss</a>
                        <CopyToClipboardButton contents={"@eye4eventsbyss"} />
                    </Text>
                </Center>
            </Box>
            </main>
        </div>
        </>



    )
}

export default Book;
