import { useEffect, useState } from "react";
import { Image } from '@chakra-ui/react';
import { Link } from "react-router-dom";

type imageFlipProps = {
    frontImageUrl:string,
    backImageUrl:string,
    reverse: boolean,
    mobile: boolean,
    interval: number,
    delay: number
}



const ImageFlip:React.FC<imageFlipProps> = (props) => {
    const [flipped, setFlipped] = useState(false);
    const [firstTime, setFirstTime] = useState(true);
    const [startTime, setStartTime] = useState(Date.now());


    const transformDirection = props.reverse ? "rotateY(180deg)" : "rotateY(-180deg)"

    // useEffect(() => {

    //     const interval = setInterval(() => {
    //         setFlipped(!flipped);
    //     }, props.interval+delayAmount);
    //     return () => clearInterval(interval);
    // }, [flipped]);

    // useEffect(() => {
    //     if(delayAmount==1000) {
    //         setDelayAmount(0);
    //     }
    // }, [flipped])

    // useEffect(() => {
    //     const timerId = setInterval(() => {
    //       const elapsedTime = Date.now() - startTime;
    //       if(!firstTime) {
    //           setFlipped(x => !x)
    //       }
    //       else if ( elapsedTime >= props.interval + props.delay) {
    //         setFlipped(prevFlipped => !prevFlipped);
    //         setStartTime(Date.now());
    //         setFirstTime(false);
    //       } 
    //     }, props.interval);
    //     return () => clearInterval(timerId);
    //   }, [props.interval, props.delay, startTime]);
    let timerId:any;
    useEffect(() => {
        setTimeout(() => {
        timerId = setInterval(() => {
            setFlipped(prevFlipped => !prevFlipped);
          }, props.interval);
        }, props.delay);
        return () => clearInterval(timerId);
      }, []);

    return (
        <div className="flip-container" style={{
            height: props.mobile ? "45vw" : "22vw",
            width: props.mobile ? "45vw" : "22vw",
        }}>
            <div
                className="flipper"
                style={{
                    perspective: 1000,
                    transition: ".8s",
                    transformStyle: "preserve-3d",
                    position: "relative",
                    transform: flipped ? transformDirection : "rotateY(0deg)",
                }}
            >
                <div
                className="front"
                style={{
                    backfaceVisibility: "hidden",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 2,
                    height: props.mobile ? "45vw" : "22vw",
                    width: props.mobile ? "45vw" : "22vw",
                }}
                >
                <Link to="walls">
                    <Image src={props.frontImageUrl} height={["45vw","22vw"]} px={[1,1]} />
                </Link>
                </div>
                <div
                    className="back"
                    style={{
                        backfaceVisibility: "hidden",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        transform: "rotateY(180deg)",
                        height: props.mobile ? "45vw" : "22vw",
                        width: props.mobile ? "45vw" : "22vw",
                    }}
                >
                <Link to="walls">
                    <Image src={props.backImageUrl} height={["45vw","22vw"]} px={[1,1]} />
                </Link>
                </div>
            </div>
        </div>
    );

}


export default ImageFlip;