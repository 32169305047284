import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import {Center, Image} from '@chakra-ui/react';


const styles = {

  root: {
    padding: '0 40px',
  },
  slideContainer: {
    padding: '0 10px',
  },


  tabs: {
    background: '#f2e076',
    color: 'black',
    
  },
  slide: {
    padding: 0,
    minHeight: 100,
    color: '#f2e076',
    
  },
  slide1: {
    backgroundColor: '#FFF',
  },
  slide2: {
    backgroundColor: '#FFF',
  },
  slide3: {
    backgroundColor: '#FFF',
  },
  slide4: {
    backgroundColor: '#FFF',
  },
};

type carouselProps = {
  imgProps: {
    img1: string,
    img2: string|undefined,
    img3: string|undefined
  }

}

const MobileImageCarousel:React.FC<carouselProps> = (props) => {
  const [index, setIndex] = useState(0);

  const handleChangeIndex = (index:any) => {
    setIndex(index);
  };

  return (
    <div>
      <SwipeableViews style={styles.root} slideStyle={styles.slideContainer} index={index} onChangeIndex={handleChangeIndex}>
        <div style={Object.assign({}, styles.slide, styles.slide1)}>
            <Center>
                { props.imgProps.img1 && <Image src={props.imgProps.img1} borderRadius={'lg'} height={"100vw"} px={0} /> }
            </Center>
        </div>
        <div style={Object.assign({}, styles.slide, styles.slide2)}>
            <Center>
            { props.imgProps.img2 && <Image src={props.imgProps.img2} borderRadius={'lg'} height={"100vw"} px={0} /> }
            </Center>
        </div>
        <div style={Object.assign({}, styles.slide, styles.slide3)}>
            <Center>
            { props.imgProps.img3 && <Image src={props.imgProps.img3} borderRadius={'lg'} height={"100vw"} px={0} /> }
            </Center>
        </div>
      </SwipeableViews>


    </div>
  );
}

export default MobileImageCarousel;